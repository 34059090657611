import { Home, List, Timeline } from '@material-ui/icons';
import MainPage from '../pages/MainPage';
import LoginPage from '../pages/LoginPage';
import EventsPage from '../pages/EventsPage';
import GraphsPage from '../pages/GraphsPage';

const Routes = [
    {
        path: '/login',
        sidebarName: 'Anmelden',
        navbarName: 'Anmelden',
        icon: Home,
        component: LoginPage,
        top: true,
        type: "login",
        showInSidebar: false
    },
    {
    path: '/overview',
    sidebarName: 'Übersicht',
    navbarName: 'Übersicht',
    icon: Home,
    component: MainPage,
    showInSidebar: true
},
{
    path: '/events',
    sidebarName: 'Ereignisse',
    navbarName: 'Ereignisse',
    icon: List,
    component: EventsPage,
    showInSidebar: true
},
{
    path: '/history',
    sidebarName: 'Verlauf',
    navbarName: 'Verlauf',
    icon: Timeline,
    component: GraphsPage,
    showInSidebar: true
}

];

export default Routes;