import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Graph from './graph';


const useStyles = makeStyles((theme) => ({
    graphPaper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        minHeight: '300px',
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingBottom: '5px',
        width: '100%'
    },
    graphTitle: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexGrow: 1
    }
}));


function GraphContainer(props) {
    const {data, graphs, capabilityData, prefersDarkMode} = props;
    let classes = useStyles();

    return (
        <Grid item xs={12} md={6}>
            <Paper className={classes.graphPaper}>
                <Typography 
                    className={classes.graphTitle}
                    variant="overline"
                    display="block"
                    gutterBottom
                >
                        {graphs.name}
                </Typography>
                
                {!data && 
                    <div className={classes.container}>
                        <CircularProgress />
                    </div>}
                {data && <Graph data={data} graphs={graphs} capabilityData={capabilityData} prefersDarkMode={prefersDarkMode}/>}
                
            </Paper>
        </Grid>
    )
}

export default GraphContainer;