import React from 'react';
import Typography from '@material-ui/core/Typography';
import '../css/nodata.css'

function NoData(props) {
    return (
        <div className="nodataDiv" hidden={false}>
            <Typography align="center" variant="h5" component="h2">Für das ausgewählte Datum sind keine Daten vorhanden.</Typography>
        </div>
    );
}

export default NoData;