import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

class ConditionalTooltip extends Component {
    
    render() {
        var { condition, ...other } = this.props;
        if (condition) {
            return (
                <Tooltip {...other}>
                    {this.props.children}
                </Tooltip>
            );
        } else {
            return (
                <>
                    {this.props.children}
                </>
            );
        }
    }
}

ConditionalTooltip.defaultProps = {
    condition: false
};

export default ConditionalTooltip;