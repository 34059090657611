import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import DateSelector from '../components/DateSelector';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LoadingAnimation from '../components/LoadingAnimation';
import NoData from '../components/NoData';
import ChangesList from '../components/ChangesList';
import connection from '../services/connection';
import 'moment/locale/de';
import moment from 'moment-timezone';
import Copyright from '../components/Copyright';
let axios = require('axios');
let CancelToken = axios.CancelToken;

moment.tz.setDefault("Europe/Berlin");
moment.locale('de');

const useStyles = makeStyles((theme) => ({
    contentDesktop: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1,
        overflow: 'auto',
        marginTop: '64px',
        paddingTop: '20px',
        height: 'calc(100% - 64px)'
    },
    contentMobile: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1,
        overflow: 'auto',
        marginTop: '56px',
        paddingTop: '20px',
        height: 'calc(100% - 56px)'
    },
    pickerGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '100%'
    },
    eventsGrid: {
        width: '100%',
    },
    datetime: {
        display: 'none'
    }
}));

let isLoading = false;
let oldDate = "";

function EventPage(props) {
    document.title = "Homepilot - Ereignisse";
    let cancel;
    const [data, setData] = useState(null);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        return () => {
            setIsMounted(false);
        }
    }, []);

    const { deviceData, prefersDarkMode, isDesktop, handleLoadingStart, handleLoadingStop } = props;
    let date = moment().tz("Europe/Berlin");
    date.hours(0);
    date.minutes(0);
    date.seconds(0);
    date.milliseconds(0);
    let dateValid = date.isValid();
    let dateInFuture = false;
    
    if (props.match.params.year && props.match.params.month && props.match.params.day) {
        let year = parseInt(props.match.params.year);
        let month = parseInt(props.match.params.month);
        let day = parseInt(props.match.params.day);
        let currentDate = date.clone();
        currentDate.hour(0);
        currentDate.minute(0);
        currentDate.second(0);
        currentDate.millisecond(0);
        date = moment.tz([year, month-1, day ], "Europe/Berlin");
        if (date - currentDate > 0) { dateInFuture = true};
        dateValid = date.isValid();
    }

    let sortEventData = (a,b) => {
        if (a.time < b.time) return 1;
        if (a.time > b.time) return -1;
        if (a.type < b.type) return -1;
        if (a.type > b.type) return 1;
        let deviceA = deviceData[a.device];
        let deviceB = deviceData[b.device];
        if (deviceA.mName < deviceB.mName) return -1;
        if (deviceA.mName > deviceB.mName) return 1;
        return 0;
    };

    let loadData = function() {
        handleLoadingStart();
        isLoading = true;
        let URL = "/data/changes/" + date.format("YYYY-MM-DD");
        connection.get(
            URL, 
            {cancelToken: new CancelToken(c => cancel = c)}).then((result) => {
                
                let newData = result.data;
                let filteredData = newData.data.filter((event) => event.previous !== null);
                let sortedData = filteredData.sort(sortEventData);
                newData.data = sortedData;
                handleLoadingStop();
                isLoading = false;
                oldDate = date.format("YYYY-MM-DD");
                setData(newData);
        }).catch((error) => { handleLoadingStop(); console.log(error)});
    }

   
    const classes = useStyles();
    
    let dateChanged = function(x) {
        if (x === date) { return; }
        cancel && cancel();
        isLoading = false;
        setData(null);
        let target = x.format("YYYY-MM-DD");
        props.history.push("/events/"+target);
    }

    if (deviceData && (!data || (date.format("YYYY-MM-DD") !== oldDate)) && isMounted && dateValid && !isLoading) {
        setData(null);
        loadData();
    }

    if (!dateValid || dateInFuture) {
        if (!dateValid) {
            return (
                <Container component="div" maxWidth={false} className={isDesktop ? classes.contentDesktop : classes.contentMobile}>
                    <Typography variant="h5" component="h2">{props.match.params.year}-{props.match.params.month}-{props.match.params.day} ist kein gültiges Datum.</Typography>
                </Container>
        );
        }
        if (dateInFuture) {
            return (
                <Container component="div" maxWidth={false} className={isDesktop ? classes.contentDesktop : classes.contentMobile}>
                    <Typography variant="h5" component="h2">Noch können wir nicht in die Zukunft gucken.</Typography>
                </Container>
            );
        }
    } else {
        return (
            <Container component="div" maxWidth={false} className={isDesktop ? classes.contentDesktop : classes.contentMobile}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item className={classes.pickerGrid}>
                        <DateSelector date={date} onChange={dateChanged} minDate={new Date("2020-08-31")}/>
                    </Grid>
                    <Grid item className={classes.eventsGrid}>
                        {(data === null || !deviceData) && <LoadingAnimation/>}
                        {(data !== null && data.data.length > 0 && deviceData) && <><ChangesList prefersDarkMode={prefersDarkMode} isDesktop={isDesktop} data={data} deviceData={deviceData} loadData={loadData}/><Copyright /></>}
                        {(data !== null && data.data.length === 0) && <NoData/>}
                    </Grid>
                </Grid>
            </Container>
        
        )
    }
}

export default withRouter(EventPage);