import React, {useState, useRef, createElement} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { DatePicker } from '@material-ui/pickers';
import 'moment/locale/de';
import moment from 'moment-timezone';
moment.tz.setDefault("Europe/Berlin");
moment.locale('de');

export const ArrowBackIosIcon = () => createElement(ArrowForwardIosIcon, { style: { transform: 'rotate(180deg)' }});

const useStyles = makeStyles((theme) => ({
    datetime: {
        display: 'none'
    }
}));

function DateSelector(props) {
    const {date, onChange, minDate} = props;
    let lastDate = true;
    const [isOpen, setIsOpen] = useState(false);
    let currentDate = moment().tz("Europe/Berlin");
    currentDate.hours(0);
    currentDate.minutes(0);
    currentDate.seconds(0);
    currentDate.milliseconds(0);
    if (date - currentDate < 0) { lastDate = false; }
    let diff = date - currentDate;
    let yesterday = date.clone();
    yesterday.subtract(1, 'd');
    let tomorrow = date.clone();
    tomorrow.add(1, 'd');
    const classes = useStyles();
    const datePicker = useRef(null);
    const [selectedDate, handleDateChange] = useState(date.toISOString());
    let firstDate = false;
    if (minDate) {
        if (minDate > date) {
            firstDate = true;
        }
    }

    function nextDay() {
        changeDate(tomorrow);
    }

    function previousDay() {
        changeDate(yesterday);
    }

    function changeDate(newDate) {
        handleDateChange(newDate);
        if (onChange) {
            onChange(newDate);
        }
    }

    let openDatePicker = function() {
        setIsOpen(true);
    }


    return (
        <Paper>
            <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                <IconButton disabled={firstDate} onClick={previousDay}>
                    <ArrowBackIosIcon/>
                </IconButton>
                <Button onClick={openDatePicker}>{date.format("Do MMMM YYYY")}</Button>        
                <IconButton disabled={lastDate} onClick={nextDay}>
                    <ArrowForwardIosIcon/>
                </IconButton>
            </ButtonGroup>
            <DatePicker 
                value={selectedDate}
                onChange={changeDate}
                ref={datePicker}
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                disableToolbar={true}
                disableFuture={true}
                autoOk={true}
                className={classes.datetime}
                minDate={minDate ? minDate : new Date("1900-01-01")}
                locale="de"
                cancelLabel="Abbrechen"
                showTodayButton={true}
                todayLabel="Heute"
            />
        </Paper>
    )



}

export default DateSelector;