import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ChangeMessage from './ChangeMessage';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

function ChangeElement(props) {
    const {prefersDarkMode} = props;
    const useStyles = React.useMemo(
        () =>
        makeStyles((theme) => ({
        paper: {
            marginTop: '20px',
            marginBottom: '20px',
            width: '100%'
        },
        alert: {
            backgroundColor: "rgba(255, 123, 0, 0.1)",
            width: '100%'
        },
        reachable: {
            backgroundColor: "#ff0000",
            color: "#ffffff",
            width: '100%'
        },
        movement: {
            backgroundColor: "rgba(0, 60, 255, 0.1)",
            width: '100%'
        },
        power: {
            backgroundColor: "rgba(255, 255, 0, 0.1)",
            width: '100%'
        },
        automatic: {
            backgroundColor: "rgba(255, 0, 255, 0.1)",
            width: '100%'
        },
        time: {
            ...theme.typography.button,
        },
        biggerTime: {
            fontSize: "1.275rem"
        },
        weather: {
            backgroundColor: "rgba(0, 255, 81, 0.1)",
            width: '100%'
        },
        timeblock: {
            marginBottom: '20px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexGrow: 1,
            flexShrink: 0,
        },
        eventcontainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexGrow: 1,
            
        },
        fullwidth: {
            width: '100%',
        },
        withPadding: {
            padding: '5px',
            paddingLeft: '15px'
        },
        elementPaper: {
            width: '100%',
        },
        withBorder: {
            borderTop: `1px solid ${prefersDarkMode ? 'rgba(97,97,97,1)': 'rgba(224,244,244,1)'}`,
        },
        multipleElement: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexGrow: 1
        },
        singleElement: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexGrow: 1
        },
        withMinHeight: {
            minHeight: '60px'
        },
        default: {}
    }),
    [prefersDarkMode]
    )
    );
    const classes = useStyles();
    const {event, multiple, deviceData} = props;
    const [open, setOpen] = React.useState(false);

    function formatValue(type, value) {
        switch (type) {
            case 1:
                return value === 0 ? "nicht erreichtbar" : "erreichbar";
            case 2:
                return value === 0 ? "kein Regen" : "Regen";
            case 3:
                return value === 0 ? "keine Sonne" : "Sonne";
            case 4:
                return value === 0 ? "kein Rauch" : "Rauch";
            case 5:
                return value === 0 ? "nicht niedrig" : "niedrig"
            case 6:
            case 7:
            case 8:
            case 10:
                return `${value} %`;
            case 9:
                return value === 100 ? "Handbetrieb" : "Automatik"
            case 11:
                return `${value} lx`;
            case 12:
            case 13:
                return `${value/10} °`;
            case 14:
                return `${value/10} °C`
            case 15:
            case 16:
            case 17:
            case 18:
            case 19:
            case 20:
            case 21:
            case 22:
            case 23:
            case 24:
            case 25:
            case 26:
            case 27:
                let result = "";
                if (value === 3) { result = "läuft nicht mehr"}
                if (value === 2) { result = "aktivviert"}
                if (value === 1) { result = "deaktiviert"}
                if (value === 0) { result = "läuft"}
                return result;
            case 28:
                return value === 0 ? "aus" : "an";
            case 29:
                return `${value/10} m/s`;
            default:
                return `${value}`

        }
    }

    function compare(a,b) {
        if (deviceData[a.device].name < deviceData[b.device].name) {
            return -1;
        }
        if (deviceData[a.device].name < deviceData[b.device].name) {
            return 1;
        }
        return 0;
    }

    if (multiple && event && deviceData) {
        event.eventsByType.sort(compare);
    }


    function classForType(type) {
        switch (type) {
            case 1:
                return classes.reachable
            case 2:
            case 3:
                return classes.weather
            case 4:
            case 5:
            case 9:
                return classes.alert
            case 15:
            case 16:
            case 17:
            case 18:
            case 19:
            case 20:
            case 21:
            case 22:
            case 23:
            case 24:
            case 25:
            case 26:
            case 27:
                return classes.automatic
            case 10:
            case 28:
                return classes.power
            case 7:
            case 8:
                return classes.movement
            default:
                return classes.default
        }
    }

    if (!multiple) {
        return (
            <div className={`${classForType(event.type)} ${classes.withPadding} ${classes.fullWidth} ${classes.withBorder} ${classes.withMinHeight} ${classes.singleElement}`}>
                <ChangeMessage did={event.device} type={event.type} oldValue={event.previous} newValue={event.current} deviceData={deviceData}/>
            </div>
        )
    } else {
        return (
            <div className={`${classForType(event.type)} ${classes.withPadding} ${classes.fullWidth} ${classes.withBorder}`}>
                <div className={classes.multipleElement}>
                    <ChangeMessage type={event.type} event={event} multiple={true} deviceData={deviceData}/>
                    <IconButton onClick={() => setOpen(!open)}>
                        {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    </IconButton>
                </div>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Gerät
                                </TableCell>
                                <TableCell>
                                    von
                                </TableCell>
                                <TableCell>
                                    zu
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {event.eventsByType.map((item) => (
                            <TableRow key={`${item.time}-${item.device}-${item.type}-tr`}>
                            <TableCell>
                                {deviceData[item.device].name}
                            </TableCell>
                            <TableCell>
                                {formatValue(item.type, item.previous)}
                            </TableCell>
                            <TableCell>
                                {formatValue(item.type, item.current)}
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </Collapse>
            </div>
        )
    }
}

export default ChangeElement;