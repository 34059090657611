import React from 'react';
import Typography from '@material-ui/core/Typography';
import Logo from '../data/icon.png';
import '../css/loading.css';

function LoadingAnimation(props) {
    return (
        <div className="loadingDiv" hidden={false}>
            <img alt="spinning logo" className="loadingLogo" src={Logo}/>
            <Typography align="center" variant="h5" component="h2">Daten werden geladen...</Typography>
        </div>
    );
}

export default LoadingAnimation;