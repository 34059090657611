import React from 'react';
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import valueTransforms from '../helpers/valueTransforms';
import MdiNoRadio from './icons/MdiNoRadio';
import MdiManual from './icons/MdiManual';
import MdiFire from './icons/MdiFire';
import MdiLowBattery from './icons/MdiLowBattery';
import MdiBattery from './icons/MdiBattery';
import MdiLightbulb from './icons/MdiLightbulb';
import MdiShutter from './icons/MdiShutter';
import MdiShutterV from './icons/MdiShutterV';
import MdiWind from './icons/MdiWind';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  translateSmall: {
    transform: "translate(-5px, 4px)"
  },
  translateLarge: {
    transform: "translateY(0.25rem)"
  },
  gridIconCenter: {
      textAlign: "center",
      marginBottom: "10px"
  },
  noLeftRightPadding: {
      paddingLeft: '0px',
      paddingRight: '0px'
  },
  fullWidth: {
      width: '100%'
  },
  tableGrid: {
      width: '100%',
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingBottom: '5px'
  },
  noLineBreak: {
      whiteSpace: "nowrap"
  }
}));

function DeviceLine(props) {
    if (!props.deviceData || !props.device) {
        return <></>
    }

    const {classes} = props;

    function check(devId, dataId) {
        if (!props.deviceData[devId]) {return undefined;}
        if (!props.deviceData[devId][dataId]) {return undefined;}
        return valueTransforms[dataId](props.deviceData[devId][dataId].current)
    }

    function getPrimary() {
        switch (props.device.type) {
            case 1: return 8;
            case 2: return 7;
            case 3: return 28;
            case 4: return 28;
            case 5: return 10;
            case 6: return 6;
            case 7: return 8;
            default: return 0;

        }
    }

    function getPrimaryString() {
        switch (props.device.type) {
            case 1: return (<span className={classes.noLineBreak}>{props.deviceData[props.device.id][getPrimary()].current} %</span>);
            case 2: return (<span className={classes.noLineBreak}>{props.deviceData[props.device.id][getPrimary()].current} %</span>);
            case 3: return props.deviceData[props.device.id][getPrimary()].current > 0 ? 'an' : 'aus'
            case 4: return props.deviceData[props.device.id][getPrimary()].current > 0 ? 'an' : 'aus'
            case 5: return (<span className={classes.noLineBreak}>{props.deviceData[props.device.id][getPrimary()].current} %</span>);
            case 6: return (<span className={classes.noLineBreak}>{props.deviceData[props.device.id][getPrimary()].current} %</span>);
            case 7: return (<span className={classes.noLineBreak}>{props.deviceData[props.device.id][getPrimary()].current} %</span>);
            default: return "";
        }
    }

    function getPrimaryIcon() {
        switch (props.device.type) {
            case 1: return (<MdiShutterV value={props.deviceData[props.device.id][8].current}/>);
            case 2: return (<MdiShutter value={props.deviceData[props.device.id][7].current}/>);
            case 3: return (<MdiLightbulb value={props.deviceData[props.device.id][28].current}/>);
            case 4: return (<MdiLightbulb value={props.deviceData[props.device.id][28].current}/>);
            case 5: return (<MdiLightbulb value={props.deviceData[props.device.id][10].current}/>);
            case 6: return (<MdiBattery value={props.deviceData[props.device.id][6].current}/>);
            case 7: return <></>;
            case 8: return <></>;
        }
    }

    function getSecondary() {
        switch (props.device.type) {
            case 1: return 7;
            default: return 0;
        }
    }

    function getSecondaryString() {
        switch (props.device.type) {
            case 1: return (<span className={classes.noLineBreak}>{props.deviceData[props.device.id][getSecondary()].current} %</span>);
            default: return "";
        }
    }

    function getSecondaryIcon() {
        switch (props.device.type) {
            case 1: return (<MdiShutter value={props.deviceData[props.device.id][7].current}/>)
            default: return <></>;
        }
    }

    return (
        <TableRow className={props.classes.fullWidth}>
            <TableCell align="right" className={props.classes.noLeftRightPadding}>
                { check(props.device.id, 1) === 1 ? <MdiNoRadio color="#f44336"/> : <></> }
                { check(props.device.id, 4) > 0 ? <MdiFire color="##f44336"/> : <></> }
                { check(props.device.id, 5) > 0 ? <MdiLowBattery color="#f44336"/> : <></> }
                { check(props.device.id, 9) > 0 ? <MdiManual color="#fdd835"/> : <></> }
                { check(props.device.id, 27) === 3 ? <MdiWind color="#1565c0"/> : <></>}
                
            </TableCell>
            <TableCell align="left" className={props.classes.fullWidth}>
                {props.device.name}
            </TableCell>
            
            <TableCell align="right" className={props.classes.noLeftRightPadding}>
            { getSecondaryIcon() }
            </TableCell>
            <TableCell align="right" className={props.classes.noLeftRightPadding}>
            { getSecondary() ? getSecondaryString() : "" }
            </TableCell>
            <TableCell align="right" className={props.classes.noLeftRightPadding}>
                {getPrimaryIcon()}
            </TableCell>
            <TableCell align="right" className={props.classes.noLeftRightPadding}>
                { getPrimary() ? getPrimaryString() : ""}
            </TableCell>
        </TableRow>
    )
}

function RoomPanel(props) {
    const {
        room,
        roomData,
        deviceData,
        data
    } = props;

    const classes = useStyles();

    const sortByTypeAndName = function(a,b) {
        if (deviceData[a].type < deviceData[b].type) return -1;
        if (deviceData[a].type > deviceData[b].type) return 1;
        if (deviceData[a].name < deviceData[b].name) return -1;
        if (deviceData[a].name > deviceData[b].name) return 1;
        return 0;
    }

    if (!room || !roomData || !deviceData || !data) {
        return (<></>)
    }
    return (
        <Paper>
            <Grid container direction="column" justify="flex-start" alignItems="center">
                <Grid item xs={12}>
                    <Typography
                        variant="overline"
                        display="block"
                        gutterBottom
                    >
                        {roomData.name}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.tableGrid}>
                <Table size="small" className={classes.fullWidth}>
                <TableBody className={classes.fullWidth}>
                {roomData.devices.sort(sortByTypeAndName).map((item) => (
                    <DeviceLine device={deviceData[item]} deviceData={data} classes={classes}/>
                   
                ))}
                </TableBody>
                </Table>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default RoomPanel;