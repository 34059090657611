import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import connection from './services/connection';
import { session } from './security/sessionmanager';
import interceptorsetup from './helpers/interceptorsetup';

interceptorsetup(connection, session);

ReactDOM.render(
  
    <App />,
  document.querySelector('#root'),
);
