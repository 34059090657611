import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import RoomPanel from './RoomPanel';

const useStyles = makeStyles((theme) => ({
    fullWidth: {
      width: '100%'
    }
}));

function FloorOverview(props) {
    const {combinedData, deviceData, data} = props;

    const classes = useStyles();

    if (!combinedData || !data) {
        return (<></>)
    }

    let filteredRooms = Object.keys(combinedData.rooms).filter(item => (combinedData.rooms[item].id != 9));
    
    return (
        <Grid container direction="column" justify="center" className={classes.fullWidth}>
            <Grid item  className={classes.fullWidth}>
                <Typography variant="h5" component="h1" gutterBottom>{combinedData.name}</Typography>
            </Grid>
            <Grid item container justify="flex-start" spacing={1}>
                    {filteredRooms.map((room) => (
                        <Grid item className={classes.fullWidth} xs={12} sm={6} md={5} lg={4}>
                            <RoomPanel room={room} roomData={combinedData.rooms[room]} deviceData={deviceData} data={data}
                            />
                        </Grid>
                    ))}
            </Grid>
        </Grid>
    );
}


export default FloorOverview;