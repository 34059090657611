const valueTransforms = {
    1: (value) => { return 1-value; },
    2: (value) => { return value; },
    3: (value) => { return value; },
    4: (value) => { return value; },
    5: (value) => { return value; },
    6: (value) => { return value; },
    7: (value) => { return value; },
    8: (value) => { return value; },
    9: (value) => { return value > 0 ? 1 :  0;},
    10: (value) => { return value; },
    11: (value) => { return value; },
    12: (value) => { return value/10; },
    13: (value) => { return value/10; },
    14: (value) => { return value/10; },
    15: (value) => { return 3-value; },
    16: (value) => { return 3-value; },
    17: (value) => { return 3-value; },
    18: (value) => { return 3-value; },
    19: (value) => { return 3-value; },
    20: (value) => { return 3-value; },
    21: (value) => { return 3-value; },
    22: (value) => { return 3-value; },
    23: (value) => { return 3-value; },
    24: (value) => { return 3-value; },
    25: (value) => { return 3-value; },
    26: (value) => { return 3-value; },
    27: (value) => { return 3-value; },
    28: (value) => { return value; },
    29: (value) => { return value/10; }
}

export default valueTransforms;