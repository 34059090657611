var interceptorSetup = function(connection, session) {
    if (global.connectionIntercepterSetup === undefined) {
        connection.interceptors.request.use(function(config) {
            const token = session.getToken();
            config.headers.Authorization = token ? `Bearer ${token}` : '';
            return config;
        });

        connection.interceptors.response.use( (response) => {
            return response;
        }, (error) => {
            if (error.response === undefined || error.response.status === undefined || error.response.status !== 401) {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
            let url = error.config.url.replace(error.config.baseURL, '');

            if (url === '/users/authenticate') {
                session.clear();
                return new Promise((resolve, reject) => {
                    reject(error);
                })
            }

            if (url === '/users/refreshtoken') {
                session.clear();
                return new Promise((resolve, reject) => {
                    reject(error);
                })
            }

            return session.refreshToken()
                .then((token) => {
                    const config = error.config;
                    config.headers['Authorization'] = `Bearer ${token}`;

                    return new Promise((resolve, reject) => {
                        connection.request(config).then(response => {
                            resolve(response);
                        }).catch((error) => {
                            reject(error);
                        })
                    })
                })
                .catch((error) => {
                    Promise.reject(error);
                })
        })
        global.connectionIntercepterSetup = true;
    };

}

module.exports = interceptorSetup;