import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {session} from '../security/sessionmanager';

const PrivateRoute = ({ component: Component, ...rest}) => (
    
    <Route {...rest} render={props => (
            session.isAuthenticated() ?
            (<Component {...props} {...rest}/>)
            :
            (
                <Redirect to={{
                    pathname: '/login',
                    state: { from: props.location }
                }}
                />
            ) 
    )}
    />
)

export default PrivateRoute;