import React, { Component } from 'react';
import MdiIcon from '@mdi/react';
import { mdiWeatherWindy } from '@mdi/js';

class MdiWind extends Component {
    render() {
        let { className, ...rest} = this.props;
        return (
            <MdiIcon path={mdiWeatherWindy} className={[this.props.className, "MuiSvgIcon-root"].join(' ')}  {...rest}/>
        );
    }
}

export default MdiWind;