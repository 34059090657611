import React, { Component } from 'react';
import MdiIcon from '@mdi/react';

let mdiShutterOpen = 'M11 3v2h-8v2h8v3h-8v2h8v3h-8v2h8v3h-8v2h8v3h1v-3h8v-2h-8v-3h8v-2h-8v-3h8v-2h-8v-3h8v-2h-8v-2h-1z';
let mdiShutterClosed = 'M11 3v3l-5.656854 -5.656854v2l5.656854 5.656854v3l-5.656854 -5.656854v2l5.656854 5.656854v3l-5.656854 -5.656854v2l5.656854 5.656854v4h1v-3l5.656854 5.656854v-2l-5.656854 -5.656854v-3l5.656854 5.656854v-2l-5.656854 -5.656854v-3l5.656854 5.656854v-2l-5.656854 -5.656854v-4h-1z'
let mdiShutterUp = 'M11 3v4l-5.656854 5.656854v2l5.656854 -5.656854v3l-5.656854 5.656854v2l5.656854 -5.656854v3l-5.656854 5.656854v2l5.656854 -5.656854v3h1v-4l5.656854 -5.656854v-2l-5.656854 5.656854v-3l5.656854 -5.656854v-2l-5.656854 5.656854v-3l5.656854 -5.656854v-2l-5.656854 5.656854v-4h-1z'


class MdiShutterV extends Component {
    getPath(value) {
        let cat = Math.floor(value/10);
        switch (cat) {
            case 0: return mdiShutterUp;
            case 1: return mdiShutterUp;
            case 2: return mdiShutterUp;
            case 3: return mdiShutterOpen;
            case 4: return mdiShutterOpen;
            case 5: return mdiShutterOpen;
            case 6: return mdiShutterOpen;
            case 7: return mdiShutterClosed;
            case 8: return mdiShutterClosed;
            case 9: return mdiShutterClosed;
            case 10: return mdiShutterClosed;
            default: return mdiShutterClosed;
        }
    }

    render() {
        let { className, value, ...rest} = this.props;
        return (
            <MdiIcon path={this.getPath(value)} className={[this.props.className, "MuiSvgIcon-root"].join(' ')}  {...rest}/>
        );
    }
}

export default MdiShutterV;