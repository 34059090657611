import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ChangeMessage from './ChangeMessage';
import ChangeElement from './ChangeElement';
import '../css/decoration.css'
import 'moment/locale/de';
import moment from 'moment-timezone';
moment.tz.setDefault("Europe/Berlin");
moment.locale('de');





function ChangeList(props) {
    const {prefersDarkMode} = props;


    const useStyles = React.useMemo(
        () =>
            makeStyles((theme) => ({
                paper: {
                    marginTop: '20px',
                    marginBottom: '20px',
                    width: '100%'
                },
                alert: {
                    backgroundColor: "rgba(255, 123, 0, 0.1)",
                    width: '100%'
                },
                reachable: {
                    backgroundColor: "#ff0000",
                    color: "#ffffff",
                    width: '100%'
                },
                movement: {
                    backgroundColor: "rgba(0, 60, 255, 0.1)",
                    width: '100%'
                },
                power: {
                    backgroundColor: "rgba(255, 255, 0, 0.1)",
                    width: '100%'
                },
                automatic: {
                    backgroundColor: "rgba(255, 0, 255, 0.1)",
                    width: '100%'
                },
                time: {
                    ...theme.typography.button,
                },
                biggerTime: {
                    fontSize: "1.275rem"
                },
                weather: {
                    backgroundColor: "rgba(0, 255, 81, 0.1)",
                    width: '100%'
                },
                timeblock: {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    flexGrow: 1,
                    flexShrink: 0,
                },
                eventcontainer: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexGrow: 1,
                    
                },
                fullwidth: {
                    width: '100%',
                },
                withPadding: {
                    padding: '5px',
                },
                elementPaper: {
                    width: '100%',
                    marginBottom: '20px'
                },
                withBorder: {
                    borderTop: `1px solid ${prefersDarkMode ? 'rgba(97,97,97,1)': 'rgba(224,244,244,1)'}`,
                },
                multipleElement: {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexGrow: 1
                },
                singleElement: {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexGrow: 1
                },
                withMinHeight: {
                    minHeight: '60px'
                },
                default: {}
            }),
        [prefersDarkMode]
        )
    );

    const classes = useStyles();
    const { data, deviceData, isDesktop, currentDate, loadData} = props;
    let lasttime = 0;
    const splitdata = data.data.reduce((arr, el) => {
        if (el.previous !== null) {
            if (el.time !== lasttime) {
                lasttime = el.time;
                arr.push({time: el.time, events: [el]})
            } else {
                arr[arr.length - 1].events.push({...el});
            }
        }
        return arr;
    }, []);

    const doublySplitData = splitdata.map((item) => {
        let previousType = null;
        let newEvents = item.events.reduce((arr, el) => {
            if (el.type !== previousType) {
                previousType = el.type;
                arr.push({type: el.type, eventsByType: [el]});
            } else {
                arr[arr.length - 1].eventsByType.push({...el});
            }
            return arr;
        }, []);
        return {
            time: item.time,
            events: newEvents,
        }
    });

    let previousTime = null;

    function isNewTime(time) {
        if (previousTime !== time) {
            previousTime = time;
            return true;
        }
        return false;
    }

    function classForType(type) {
        switch (type) {
            case 1:
                return classes.reachable
            case 2:
            case 3:
                return classes.weather
            case 4:
            case 5:
            case 9:
                return classes.alert
            case 15:
            case 16:
            case 17:
            case 18:
            case 19:
            case 20:
            case 21:
            case 22:
            case 23:
            case 24:
            case 25:
            case 26:
            case 27:
                return classes.automatic
            case 10:
            case 28:
                return classes.power
            case 7:
            case 8:
                return classes.movement
            default:
                return classes.default
        }
    }

    let singleEvent = (event) => (
        <ChangeElement key={`${event.time}-${event.device}-${event.type}`} prefersDarkMode={prefersDarkMode} deviceData={deviceData} event={event} multiple={false}/>
//        <div className={`${classForType(event.type)} ${classes.withPadding} ${classes.fullWidth} ${classes.withBorder} ${classes.withMinHeight} ${classes.singleElement}`}>
//            <ChangeMessage did={event.device} type={event.type} oldValue={event.previous} newValue={event.current} deviceData={deviceData}/>
//        </div>
    );

    let multipleEvents = (event) => (
        <ChangeElement key={`${event.time}-${event.type}`} deviceData={deviceData} prefersDarkMode={prefersDarkMode} event={event} multiple={true}/>
//            <div className={`${classForType(event.type)} ${classes.withPadding} ${classes.fullWidth} ${classes.withBorder} ${classes.multipleElement}`}>
//                <ChangeMessage type={event.type} event={event} multiple={true} deviceData={deviceData}/>
//                <IconButton>
//                    <ExpandMoreIcon/>
//                </IconButton>
//           </div>
    );


    
    if (deviceData) {
    if (isDesktop) {
        return (
            <Paper className={classes.paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Zeit</TableCell>
                            <TableCell>Nachricht</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.data.map((row) => row.previous !== null ? (
                            <TableRow key={`${row.time}-${row.device}-${row.type}`}>
                                <TableCell component="th" scope="row" className={classes.time}>
                                    {isNewTime(row.time) ? moment.unix(row.time).format("HH:mm:ss") : ""}
                                </TableCell>
                                <TableCell className={classForType(row.type)}><ChangeMessage did={row.device} type={row.type} oldValue={row.previous} newValue={row.current} deviceData={deviceData}/></TableCell>
                            </TableRow>) : (<></>)
                        )}    
                        
                        </TableBody>
                </Table>
            </Paper>
        );
    } else {
        return (
            <div className={classes.eventcontainer}>
            {doublySplitData.map((row) => (
                <Paper className={classes.elementPaper}>
                <div className={classes.timeblock} key={`${row.time}-timeBlock`}>
                    <div><span className={`${classes.time} ${classes.biggerTime}`}>{moment.unix(row.time).format("HH:mm:ss")}</span></div>
                    
                        {row.events.map((event) => {
                            if (event.eventsByType.length === 1) {
                                return singleEvent(event.eventsByType[0]);
                            } else {
                                return multipleEvents(event);
                            }      
                        })}
                        </div>
                    </Paper>
            ))}
            </div>
        )
    }
}
}

export default ChangeList;