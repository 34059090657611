import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateSelector from '../components/DateSelector';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

let completeData = null;

const useStyles = makeStyles((theme) => ({
    selectionBar: {
        marginBottom: '20px'
    },
    selectionBarSmall: {
    },
    datetime: {
        display: 'none'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    dateContainer: {
        display: 'flex',
        justifyContent: 'flex-end'

    },
    dateContainerSmall: {
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '20px',
    },
    deviceContainer: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    deviceContainerSmall: {
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '20px',
    },
}));

function HistorySelectionBar(props) {
    const {
        deviceData,
        floorData,
        roomData,
        deviceTypeData,
        capabilityData,
        isDesktop,
        date,
        device,
        onChange
    } = props;

    if (floorData) {
        delete floorData["1"];
    }
    if (roomData) {
        delete roomData["1"];
    }

    if (floorData && roomData && deviceData && deviceTypeData && capabilityData &&!completeData) {
        completeData = floorData;
        let floors = Object.keys(completeData);
        let rooms = Object.keys(roomData);
        for (let i = 0; i < rooms.length; ++i) {
            let roomId = roomData[rooms[i]].id;
            let floorId = roomData[rooms[i]].floor;
            if (completeData[floorId].rooms) {
                completeData[floorId].rooms[rooms[i]] = roomData[rooms[i]];
            } else {
                completeData[floorId].rooms = {};
                completeData[floorId].rooms[rooms[i]] = roomData[rooms[i]];
            }
        }
        let devices = Object.keys(deviceData);
        for (let i = 0; i < devices.length; ++i) {
            let roomId = deviceData[devices[i]].room;
            if (!roomData[roomId]) continue;
            let floorId = roomData[roomId].floor;
            if (completeData[floorId].rooms[roomId].devices) {
                completeData[floorId].rooms[roomId].devices[devices[i]] = deviceData[devices[i]];
            } else {
                completeData[floorId].rooms[roomId].devices = {};
                completeData[floorId].rooms[roomId].devices[devices[i]] = deviceData[devices[i]];
            }
        }

    }
    let sortByTypeThenName = function(a,b) {
        if (a.type < b.type) return -1;
        if (a.type > b.type) return 1;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    }

    let createDevices = (devices) => {
        let keys = Object.keys(devices);
        let objects = keys.map((key) => {return devices[key]});
        return objects.sort(sortByTypeThenName).map((device) => (
            <option key={`device-select-his-${device.id}`} value={device.id}>{device.name}</option>
        ));
    }

    let createRooms = (rooms) => {
        return Object.keys(rooms).map((room) => (
            <>
            <optgroup key={`room-key-${rooms[room].name}`} label={rooms[room].name}>
            {createDevices(rooms[room].devices)}
            </optgroup>
            </>
        ))
    }

    let dateChanged = (newDate) => {
        if (onChange) {
            onChange({
                device: device,
                date: newDate
            })
        }
    }

    let deviceChange = (event) => {
        if (onChange) {
            onChange({
                device: event.target.value,
                date: date
            })
        }
    }

    const classes = useStyles();

    if (completeData) {
        return (
            <Grid
            container
            direction="row-reverse"
            justify="space-between"
            alignItems="center"
            className = {isDesktop ? classes.selectionBar : classes.selectionbarSmall}
        >
            <Grid item xs={12} sm={6}>
                <div className={isDesktop ? classes.dateContainer : classes.dateContainerSmall}>
                    <DateSelector date={date} minDate={new Date("2020-08-31")} onChange={dateChanged}/>
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div className={isDesktop ? classes.deviceContainer : classes.deviceContainerSmall}>
                    <Paper>
                        <FormControl className={classes.formControl}>
                            <Select 
            native
            displayEmpty
            onChange={deviceChange}
            value={device}
            id="grouped-select"
        >
            {Object.keys(completeData).map((item) => (
                <>
                    <optgroup key={`floor-key-${completeData[item].name}`} label={completeData[item].name}></optgroup>
                    <>
                        {createRooms(completeData[item].rooms)}
                    </>
                </>
            ))}
        </Select>
                        </FormControl>
                    </Paper>
                </div>
            </Grid>
        </Grid>

        )
    }

}

export default HistorySelectionBar;