import React, { Component } from 'react';
import { AppBar, Toolbar, IconButton, Typography, LinearProgress } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';

import { session } from '../security/sessionmanager';
import routes from '../routes/routes';
import '../css/titlebar.css';


class Titlebar extends Component {

    currentPageInfo() {
        let currentpath = this.props.history.location.pathname;
        for (let i = 0; i < routes.length; ++i) {
            if (this.props.location.pathname.indexOf(routes[i].path) > -1) {
                return routes[i];
            }
        }
        return undefined;
    }

    leftHeader(currentPage, classes) {
        if (currentPage === undefined) {
            return (<div></div>);
        } else {
            return (
                <>
                    <currentPage.icon className={classes.toolbarIcon2} />
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.titleLeft}>
                        {currentPage.navbarName}
                    </Typography>
                </>
            );
        }
    }

    render() {
        if (session.isAuthenticated && this.props.history.location.pathname !== '/login') {
        const currentPage = this.currentPageInfo();
        const { classes , prefersDarkMode} = this.props;

        return (
            <AppBar position="absolute" className={clsx(classes.appBar, this.props.open && classes.appBarShift)}>
                <Toolbar className={clsx(classes.toolbar, prefersDarkMode ? classes.toolbarDark : classes.toolbarLight)}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={this.props.handleOpen}
                        className={clsx(classes.menuButton, this.props.open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    {this.leftHeader(currentPage, classes)}
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={[classes.titleRight, "titleRight"].join(' ')}>
                        Homepilot
          </Typography>
                </Toolbar>
                {this.props.loading && <LinearProgress color="secondary" />}
            </AppBar>
        );
        } else {
            return ( <></> )
        }
    }
}

export default withRouter(Titlebar);