const graphColors = {
    border: {
    1: '#f44336',
    2: '#03a9f4',
    3: '#ffeb3b',
    4: '#00ffff',
    5: '#ff00ff',
    6: '#00ffff',
    7: '#9c27b0',
    8: '#009688',
    9: '#9c27b0',
    10: '#fdd835',
    11: '#fdd835',
    12: '#fdd835',
    13: '#fdd835',
    14: '#4caf50',
    15: '#000000',
    16: '#000000',
    17: '#000000',
    18: '#000000',
    19: '#03a9f4',
    20: '#000000',
    21: '#000000',
    22: '#ffeb3b',
    23: '#4caf50',
    24: '#000000',
    25: '#000000',
    26: '#000000',
    27: '#3f51b5',
    28: '#fdd835',
    29: '#3f51b5'
    },
    background: {
        1: '#f4433650',
    2: '#03a9f450',
    3: '#ffeb3b50',
    4: '#00ffff50',
    5: '#ff00ff50',
    6: '#00ffff50',
    7: '#9c27b050',
    8: '#00968850',
    9: '#9c27b050',
    10: '#fdd83550',
    11: '#fdd83550',
    12: '#fdd83550',
    13: '#fdd83550',
    14: '#4caf5050',
    15: '#00000050',
    16: '#00000050',
    17: '#00000050',
    18: '#00000050',
    19: '#03a9f450',
    20: '#00000050',
    21: '#00000050',
    22: '#ffeb3b50',
    23: '#4caf5050',
    24: '#00000050',
    25: '#00000050',
    26: '#00000050',
    27: '#3f51b550',
    28: '#fdd83550',
    29: '#3f51b550'
    }

};

export default graphColors;