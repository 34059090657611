import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    name: {
        fontWeight: 'bold',
    },
    textvalue: {
        fontWeight: 'bold'
    },
    value: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap'
    },
    device: {
        fontWeight: 'bold'
    },
    datatype: {
        fontWeight: 'bold',
    },
    message: {

    },
    action: {

    }
}));


function ChangeMessage(props) {
    const classes = useStyles();
    const {did, type, oldValue, newValue, multiple, event, deviceData} = props;

    let name = function(device) {
        return (<span className={classes.name}>{deviceData[device].mName} </span>)
    }

    let action = function(type) {
        switch (type) {
            case 1:
            case 9:
            case 28:
                return (<span className={classes.action}>ist </span>)
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
            case 17:
            case 18:
            case 19:
            case 20:
            case 21:
            case 22:
            case 23:
            case 24:
            case 25:
            case 26:
            case 27:
            case 29:
                return (<span className={classes.action}>meldet </span>)
            case 7:
            case 8:
            case 10:
                return (<span className={classes.action}>ändert </span>)   
        }
    }

    let actionM = function(type) {
        switch (type) {
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 9:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
            case 17:
            case 18:
            case 19:
            case 20:
            case 21:
            case 22:
            case 23:
            case 24:
            case 25:
            case 26:
            case 27:
            case 29:
                return (<span className={classes.action}>melden </span>)
            case 1:
            case 7:
            case 8:
            case 10:
            case 28:
                return (<span className={classes.action}>ändern </span>)   
        }
    }

    let datatype = function(type) {
        switch(type) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 9:
            case 28:
                return;
            case 5:
            case 6:
                return (<span className={classes.datatype}>Batteriestand </span>);
            case 7:
                return (<span className={classes.datatype}>Position </span>);
            case 8:
                return (<span className={classes.datatype}>Lamellenposition </span>);
            case 10:
                return (<span className={classes.datatype}>Helligkeit </span>);
            case 11:
                return (<span className={classes.datatype}>Sonneneinstrahlung </span>);
            case 12:
                return (<span className={classes.datatype}>Sonnenrichtung </span>);
            case 13:
                return (<span className={classes.datatype}>Sonnenhöhe </span>);
            case 14:
                return (<span className={classes.datatype}>Temperatur </span>);
            case 15:
                return (<span className={classes.datatype}>Schließkontaktautomatik </span>);
            case 16:
                return (<span className={classes.datatype}>Abenddämmerungsautomatik </span>);
            case 17:
                return (<span className={classes.datatype}>Morgendämmerungsautomatik </span>);
            case 18:
                return (<span className={classes.datatype}>Bewegungssensorautomatik </span>);
            case 19:
                return (<span className={classes.datatype}>Regenautomatik </span>);
            case 20:
                return (<span className={classes.datatype}>Ortungsautomatik </span>);
            case 21:
                return (<span className={classes.datatype}>Rauchautomatik </span>);
            case 22:
                return (<span className={classes.datatype}>Sonnenautomatik </span>);
            case 23:
                return (<span className={classes.datatype}>Temperaturautomatik </span>);
            case 24:
                return (<span className={classes.datatype}>Zeitautomatik </span>);
            case 25:
                return (<span className={classes.datatype}>Triggerautomatik </span>);
            case 26:
                return (<span className={classes.datatype}>Warnungsautomatik </span>);
            case 27:
                return (<span className={classes.datatype}>Windautomatik </span>);
            case 29:
                return (<span className={classes.datatype}>Windgeschwindigkeit </span>);
        }

    }

    let datatypeM = function(type) {
        switch(type) {
            case 1:
                return (<span className={classes.default}>ihre <span className={classes.datatype}>Ereichbarkeit</span></span>);
            case 2:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Regenerkennung</span></span>);
            case 3:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Sonnenerkennung</span></span>);
            case 4:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Rauchwarnung</span></span>);
            case 9:
                return (<span className={classes.default}>Änderungen des <span className={classes.datatype}>Handbetriebs</span></span>);
            case 28:
                return (<span className={classes.default}>ihren <span className={classes.datatype}>Zustand</span></span>);
            case 5:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Batteriewarnung</span></span>);
            case 6:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Batteriestands</span></span>);
            case 7:
                return (<span className={classes.default}>ihre <span className={classes.datatype}>Position</span></span>);
            case 8:
                return (<span className={classes.default}>ihre <span className={classes.datatype}>Lamellenposition</span></span>);
            case 10:
                return (<span className={classes.default}>ihre <span className={classes.datatype}>Helligkeit</span></span>);
            case 11:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Sonneneinstrahlung</span></span>);
            case 12:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Sonnenrichtung</span></span>);
            case 13:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Sonnenhöhe</span></span>);
            case 14:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Temperatur</span></span>);
            case 15:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Schließkontaktautomatik</span></span>);
            case 16:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Abenddämmerungsautomatik</span></span>);
            case 17:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Morgendämmerungsautomatik</span></span>);
            case 18:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Bewegunessensorautomatik</span></span>);
            case 19:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Regenautomatik</span></span>);
            case 20:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Ortungsautomatik</span></span>);
            case 21:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Rauchautomatik</span></span>);
            case 22:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Sonnenautomatik</span></span>);
            case 23:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Temperaturautomatik</span></span>);
            case 24:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Zeitautomatik</span></span>);
            case 25:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Triggerautomatik</span></span>);
            case 26:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Warnungsautomatik</span></span>);
            case 27:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Windautomatik</span></span>);
            case 29:
                return (<span className={classes.default}>Änderungen der <span className={classes.datatype}>Windgeschwindigkeit</span></span>);
        }

    }

    let value = function(type, oldValue, newValue) {
        switch (type) {
            case 1:
                return (<span className={classes.textvalue}>{newValue === 0 ? "nicht " : ""}erreichbar</span>)
            case 2:
                return (<span className={classes.textvalue}>{newValue === 0 ? "kein " : ""}Regen erkannt</span>)
            case 3:
                return (<span className={classes.textvalue}>{newValue === 0 ? "keine " : ""}Sonne erkannt</span>)
            case 4:
                return (<span className={classes.textvalue}>{newValue === 0 ? "nicht mehr " : ""}niedrig</span>)
            case 5:
                return (<span className={classes.message}>von <span className={classes.value}>{newValue} %</span></span>)
            case 6:
            case 7:
            case 8:
            case 10:
                return (<span className={classes.message}>von <span className={classes.value}>{oldValue} %</span> auf <span className={classes.value}>{newValue} %</span></span>)
            case 9:
                return (<span className={classes.message}>im <span className={classes.textvalue}>{newValue === 100 ? "Handbetrieb" : "Automatikbetrieb"}</span></span>)
            case 11:
                return (<span className={classes.message}>von <span className={classes.value}>{newValue} lx</span></span>)
            case 12:
            case 13:
                return (<span className={classes.message}>von <span className={classes.value}>{newValue/10} °</span></span>)
            case 14:
                return (<span className={classes.message}>von <span className={classes.value}>{newValue/10} °C</span></span>)
            case 15:
            case 16:
            case 17:
            case 18:
            case 19:
            case 20:
            case 21:
            case 22:
            case 23:
            case 24:
            case 25:
            case 26:
            case 27:
                let value = "";
                if (newValue === 3) { value = "läuft nicht mehr"}
                if (newValue === 2) { value = "ist aktiviert."}
                if (newValue === 1) { value = "ist deaktiviert"}
                if (newValue === 0) { value = "läuft"}
                return (<span className={classes.message}><span className={classes.textvalue}>{value}</span></span>)
            case 28:
                return (<span className={classes.message}><span className={classes.textvalue}>{newValue === 1 ? "an" : "aus"}</span></span>)
            case 29:
                return (<span className={classes.message}><span className={classes.value}>{newValue/10} m/s</span></span>)
        }
    }
    
    if (multiple) {
        return (
            <span className="completemessage">
            <Typography variant="body2">
                {event.eventsByType.length} Geräte {actionM(type)}
                {datatypeM(type)}
            </Typography>
            </span>
        )
    } else {
        return (
            <span className="completemessage">
            <Typography variant="body2">
                {name(did)}
                {action(type)}
                {datatype(type)}
                {value(type, oldValue, newValue)}
            </Typography>
            </span>
        );
    }
}


export default ChangeMessage;