import React, { Component } from 'react';
import MdiIcon from '@mdi/react';
import { mdiWeatherSunny } from '@mdi/js';

class MdiSun extends Component {
    render() {
        let { className, ...rest} = this.props;
        return (
            <MdiIcon path={mdiWeatherSunny} className={[this.props.className, "MuiSvgIcon-root"].join(' ')} {...rest}/>
        );
    }
}

export default MdiSun;