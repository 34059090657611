import React, { Component } from 'react';
import { ListItemIcon, ListItemText, Divider, IconButton, MenuList, MenuItem, Drawer } from '@material-ui/core';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ConditionalTooltip from './conditionaltooltip';
import { session } from '../security/sessionmanager';

import routes from '../routes/routes';

import '../css/sidebar.css';





class Sidebar extends Component {



    constructor(props) {
        super(props);
        this.handleClose = this.props.handleClose.bind(this);
        this.activeRoute = this.activeRoute.bind(this);
    }



    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
    }

    render() {
        if (session.isAuthenticated && this.props.location.pathname !== '/login') {
           const { classes } = this.props;
            if (this.props.large) {
            return (
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, !this.props.open && classes.drawerPaperClose),
                        root: "drawer"
                    }}
                    open={this.props.open}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={this.props.handleClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <MenuList className="menuListElement">
                        <div className="menuListContainer" key="menuContainerKey">
                            <div className="menuListTop" key="menuListTopKey">
                                {routes.map((prop, key) => {
                                    if (!prop.showInSidebar) return null;
                                        const divider = (prop.path === "/dashboard/home" || prop.path === "/dashboard/gas") ? <Divider key={'divider-' + key}/> : <div key={'nondivider-' + key}></div>;
                                        return (
                                            <div key={'toplevel-' + key}>
                                                <ConditionalTooltip condition={!this.props.open} title={prop.navbarName} placement="right" arrow key={'CTT-' + key}>
                                                    <MenuItem component={RouterLink} to={this.activeRoute(prop.path) ? this.props.location.pathname : prop.path} selected={this.activeRoute(prop.path)} key={key}>
                                                        <ListItemIcon>
                                                            <prop.icon />
                                                        </ListItemIcon>
                                                        <ListItemText primary={prop.sidebarName} />
                                                    </MenuItem>
                                                </ConditionalTooltip>
                                                {divider}
                                            </div>
                                        );
                                })}
                            </div>
                        </div>
                    </MenuList>
                </Drawer>
            );
            } else {
            return (
                <Drawer
                    variant="temporary"
                    classes={{
                        paper: clsx(classes.drawerPaper, !this.props.open && classes.drawerPaperClose),
                        root: "drawer",
                    }}
                    open={this.props.open}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={this.props.handleClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <MenuList className="menuListElement">
                        <div className="menuListContainer" key="menuContainerKey">
                            <div className="menuListTop" key="menuListTopKey">
                                {routes.map((prop, key) => {
                                    if (!prop.showInSidebar) return null;
                                        const divider = (prop.path === "/dashboard/home" || prop.path === "/dashboard/gas") ? <Divider key={'divider-' + key}/> : <div key={'nondivider-' + key}></div>;
                                        return (
                                            <div key={'toplevel-' + key}>
                                                <ConditionalTooltip condition={!this.props.open} title={prop.navbarName} placement="right" arrow key={'CTT-' + key}>
                                                    <MenuItem component={RouterLink} to={prop.path} selected={this.activeRoute(prop.path)} key={key} onClick={this.props.handleClose}>
                                                        <ListItemIcon>
                                                            <prop.icon />
                                                        </ListItemIcon>
                                                        <ListItemText primary={prop.sidebarName} />
                                                    </MenuItem>
                                                </ConditionalTooltip>
                                                {divider}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </MenuList>
                </Drawer>
            );

            }
        } else {
            return ( <></> )
        }
    }
}

export default withRouter(Sidebar);