import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import WeatherPanel from './WeatherPanel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%'
  }
}));

function WeatherOverview(props) {
    const { weatherData, deviceData, capabilityData } = props;

    const classes = useStyles();

    if (!weatherData || !deviceData || !capabilityData) {
        return (<></>)
    }

    return (
        <Grid container direction="column" justify="center" className={classes.fullWidth}>
            <Grid item  className={classes.fullWidth}>
                <Typography variant="h5" component="h1" gutterBottom>Wetterstationen</Typography>
            </Grid>
            <Grid item container justify="flex-start" spacing={1}>
                    {Object.keys(weatherData).map((device) => (
                        <Grid item className={classes.fullWidth} xs={12} sm={6} md={5} lg={4}>
                            <WeatherPanel device={device}
                                          weatherData={weatherData[device]}
                                          deviceData={deviceData}
                                          capabilityData={capabilityData}
                            />
                        </Grid>
                    ))}
            </Grid>
        </Grid>      
    )
}

export default WeatherOverview;