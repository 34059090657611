import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import FloorOverview from '../components/FloorOverview';
import UnresponsiveAlert from '../components/UnresponsiveAlert';
import ManualAlert from '../components/ManualAlert';
import connection from '../services/connection';
import WeatherOverview from '../components/WeatherOverview';
import LoadingAnimation from '../components/LoadingAnimation';
let axios = require('axios');
let CancelToken = axios.CancelToken;

const useStyles = makeStyles((theme) => ({
  contentDesktop: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    overflow: 'auto',
    marginTop: '64px',
    paddingTop: '20px',
    height: 'calc(100% - 64px)'
},
contentMobile: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    overflow: 'auto',
    marginTop: '56px',
    paddingTop: '20px',
    height: 'calc(100% - 56px)'
},
fullWidth: {
  width: '100%'
},
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  accordion: {
    display: 'flex',
    flexDirection: 'space-between',
  },
  firstCell: {
    width: '100%',
  },
  percentageCell: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  iconCell: {
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(1)
  }
}));

let isLoading = false;

let cancel;

function MainPage(props) {
  document.title = "Homepilot - Übersicht";
  const classes = useStyles();
  const { floorData, 
          roomData,
          deviceData,
          capabilityData,
          handleLoadingStart,
          handleLoadingStop,
          isDesktop
        } = props;
  const [data, setData] = useState(null);
  const [unavailable, setUnavailable] = useState([]);
  const [manual, setManual] = useState([]);
  const [weather, setWeather] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [combined, setCombined] = useState(null);

  useEffect(() => {
      setIsMounted(true);
  }, []);

  useEffect(() => {
      return () => {
          setIsMounted(false);
      }
  }, []);

  let filterUnavailable = function(events) {
    let keys = Object.keys(events);
    let result = [];
    for (let i = 0; i< keys.length; ++i) {
      if (events[keys[i]][1]) {
        let value = events[keys[i]][1];
        if (value.current === 0) {
          if (deviceData[keys[i]].visible) { 
            result.push({device: keys[i], since: value.time});
          }
        }
      }
    }
    return result;
  }

  let filterManual = function(events) {
    let keys = Object.keys(events);
    let result = [];
    for (let i = 0; i< keys.length; ++i) {
      if (events[keys[i]][9]) {
        let value = events[keys[i]][9];
        if (value.current === 100) {
          if (deviceData[keys[i]].visible) { 
            result.push({device: keys[i], since: value.time});
          }
        }
      }
    }
    return result;
  }

  let filterWeather = function(events) {
    let keys = Object.keys(events);
    let result = {};
    for (let i = 0; i < keys.length; ++i) {
      if (deviceData[keys[i]].type === 8) {
        result[keys[i]] = events[keys[i]];
      }
    }
    return result;
  }

  let convertToCombined = function() {
    let floorKeys = Object.keys(floorData);
    let roomKeys = Object.keys(roomData);
    let deviceKeys = Object.keys(deviceData);
    let result = {};
    for (let i = 0; i < floorKeys.length; ++i) {
      let fKey = floorKeys[i];
      result[fKey] = {
        id: fKey,
        name: floorData[fKey].name,
        rooms: {}
      }
      let filteredRooms = roomKeys.filter((item) => roomData[item].floor === floorData[fKey].id);
      for (let j = 0; j < filteredRooms.length; ++j) {
        let rKey = filteredRooms[j];
        result[fKey].rooms[rKey] = { id: rKey, name: roomData[rKey].name, devices: []};
        let filteredDevices = deviceKeys.filter((item) => deviceData[item].room === roomData[rKey].id);
        result[fKey].rooms[rKey].devices = filteredDevices;
      }
    }
    return result;
  }
  

  let loadData = function() {
    handleLoadingStart();
    isLoading = true;
    let URL = "/overview";
    connection.get(
      URL, 
      {cancelToken: new CancelToken(c => cancel = c)}).then((result) => {
        let newData = result.data.lastValues;
        handleLoadingStop();
        isLoading = false;
        setData(newData);
        setUnavailable(filterUnavailable(newData));
        setManual(filterManual(newData));
        setWeather(filterWeather(newData));
    }).catch((error) => { handleLoadingStop(); console.log(error)});
  }

  if (!data && !isLoading && isMounted && deviceData && capabilityData && floorData && roomData) {
    loadData();
  }

  if (!combined && isMounted && deviceData && floorData && roomData) {
    setCombined(convertToCombined());
  }
  if (data) { 
  return (
    <>
      <Container maxWidth={false} className={isDesktop ? classes.contentDesktop : classes.contentMobile}>
      <Grid
            container
            spacing={2}
        >
        <UnresponsiveAlert unavailable={unavailable} deviceData={deviceData}/>
        <ManualAlert manual={manual} deviceData={deviceData}/>
        <Grid item className={classes.fullWidth}>
          <WeatherOverview weatherData={weather} deviceData={deviceData} capabilityData={capabilityData}/>
        </Grid>
        {Object.keys(floorData).map((item) => (item === "1" ? <></> : <Grid item className={classes.fullWidth}><FloorOverview combinedData={combined[item]} roomData={roomData} deviceData={deviceData} data={data}/></Grid>))}
        </Grid>
      </Container>
    </>
  );
  } else {
    return (
      <Container maxWidth={false} className={isDesktop ? classes.contentDesktop : classes.contentMobile}>
      <Grid
        container
        spacing={2}
        >
        <Grid item className={classes.fullWidth}>
          <LoadingAnimation/>
        </Grid>     
      </Grid>
      </Container>
    )
  }
}

export default MainPage;