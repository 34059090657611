var constants = require('../helpers/constants');
var axios = require('axios');
var axiosCookieJarSupport = require('axios-cookiejar-support');
var toughCookie = require('tough-cookie');
var CookieJar = toughCookie.CookieJar;

var getInstance = function() {
    if (global.connectionInstanceHomePilot === undefined) {
        let jar = new CookieJar();
        axios.defaults.withCredentials = true;
        let base = constants.BASEURL_PROD;
        if (process.env.NODE_ENV !== 'production') {
            base = constants.BASEURL_DEV; 
        }
        let connection = axios.create(
            {
                baseURL: base,
                withCredentials: true,
                crossdomain: true,
                jar: jar
            }
        );
        console.log(axiosCookieJarSupport);
        axiosCookieJarSupport(connection);
        connection.jar = jar;


        global.connectionInstanceHomePilot = connection;
    }
    return global.connectionInstanceHomePilot;

}

module.exports = getInstance();