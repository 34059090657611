import React from 'react';
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import valueTransforms from '../helpers/valueTransforms';
import MdiSun from './icons/MdiSun';
import MdiWind from './icons/MdiWind';
import MdiTemp from './icons/MdiTemp';
import MdiNoSun from './icons/MdiNoSun';
import MdiRain from './icons/MdiRain';
import MdiRadio from './icons/MdiRadio';
import MdiNoRadio from './icons/MdiNoRadio';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  translateSmall: {
    transform: "translate(-5px, 4px)"
  },
  translateLarge: {
    transform: "translateY(0.25rem)"
  },
  gridIconCenter: {
      textAlign: "center",
      marginBottom: "10px"
  }
}));

function WeatherPanel(props) {
    const {
        device,
        weatherData,
        deviceData,
        capabilityData
    } = props;

    const classes = useStyles();

    if (!device || !weatherData || !deviceData || !capabilityData) {
        return (<></>)
    }
    return (
        <Paper>
            <Grid container direction="column" justify="flex-start" alignItems="center">
                <Grid item xs={12}>
                    <Typography
                        variant="overline"
                        display="block"
                        gutterBottom
                    >
                        {deviceData[device].mName}
                    </Typography>
                </Grid>
                <Grid container item xs={12} justify="center" gutterBottom>
                    <Grid item xs={2} className={classes.gridIconCenter}>
                    <Tooltip title={weatherData[1].current ? "Station erreichbar" : "Station nicht erreichbar"}>
                        {weatherData[1].current ? <MdiRadio color="#bdbdbd"/> : <MdiNoRadio color="#f44336"/>}
                    </Tooltip>
                    </Grid>
                    <Grid item xs={2} className={classes.gridIconCenter}>
                    <Tooltip title={weatherData[3].current ? "Sonne" : "Keine Sonne"}>
                        {weatherData[3].current ? <MdiSun color="#fdd835"/> : <MdiNoSun color="#bdbdbd"/>}
                        </Tooltip>
                    </Grid>
                    <Grid item xs={2} className={classes.gridIconCenter}>
                    <Tooltip title={weatherData[2].current ? "Regen" : "Kein Regen"}>
                        {weatherData[2].current ? <MdiRain color="#1e88e5"/> : <MdiRain color="#bdbdbd"/>}
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="h4"
                        display="block"
                        gutterBottom
                    >
                        <MdiTemp className={classes.translateLarge} size="2rem"/>{`${valueTransforms[14](weatherData[14].current).toLocaleString('de-DE')} ${capabilityData[14].unit}`}
                    </Typography>
                </Grid>
                <Grid container item xs={12} justify="space-evenly">
                    <Grid item xs={6}>
                    <Typography
                    variant="h5"
                    display="block"
                    gutterBottom
                    align="center"
                >
                    <MdiWind className={classes.translateSmall}/>{`${valueTransforms[29](weatherData[29].current).toLocaleString('de-DE')} ${capabilityData[29].unit}`}
                    </Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography
                    variant="h5"
                    display="block"
                    align="center"
                    gutterBottom
                >
                    <MdiSun className={classes.translateSmall}/>{`${valueTransforms[11](weatherData[11].current).toLocaleString('de-DE')} ${capabilityData[11].unit}`}   
                    </Typography>
                    </Grid>
                </Grid>
                
            </Grid>
        </Paper>
    )
}

export default WeatherPanel;