import React, { Component } from 'react';
import MdiIcon from '@mdi/react';
import { mdiWindowShutter } from '@mdi/js';
import { mdiWindowShutterOpen } from '@mdi/js';

let mdiShutter3of4 = 'M3 4H21V8H19V20H17V8H7V20H5V8H3V4M8 9H16V11H8V9M8 12H16V14H8V12M8 15H16V17H8V15Z';
let mdiShutter2of4 = 'M3 4H21V8H19V20H17V8H7V20H5V8H3V4M8 9H16V11H8V9M8 12H16V14H8V12Z';
let mdiShutter1of4 = 'M3 4H21V8H19V20H17V8H7V20H5V8H3V4M8 9H16V11H8V9Z';
let mdiShutter0of4 = 'M3 4H21V8H19V20H17V8H7V20H5V8H3V4Z';

class MdiShutter extends Component {
    getPath(value) {
        let cat = Math.floor(value/10);
        switch (cat) {
            case 0: return mdiShutter0of4;
            case 1: return mdiShutter1of4;
            case 2: return mdiShutter1of4;
            case 3: return mdiShutter1of4;
            case 4: return mdiShutter2of4;
            case 5: return mdiShutter2of4;
            case 6: return mdiShutter2of4;
            case 7: return mdiShutter3of4;
            case 8: return mdiShutter3of4;
            case 9: return mdiShutter3of4;
            case 10: return mdiWindowShutter;
        }
    }

    render() {
        let { className, value, ...rest} = this.props;
        return (
            <MdiIcon path={this.getPath(value)} className={[this.props.className, "MuiSvgIcon-root"].join(' ')}  {...rest}/>
        );
    }
}

export default MdiShutter;