import React, { Component } from 'react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import 'moment/locale/de';
import moment from 'moment-timezone';
import PortableWifiOffIcon from '@material-ui/icons/PortableWifiOff';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

moment.tz.setDefault("Europe/Berlin");
moment.locale('de');

let sortByTime = function(a,b) {
    if (a.since > b.since) return -1;
    if (a.since < b.since) return 1;
    return 0;
}

class UnresponsiveAlert extends Component {
    constructor(props) {
        super(props);
        this.unreachableToIgnore = [];
        this.state = {open: false};
    }

    renderDuration(time, current) {
        let eventTime = moment.utc(time*1000);
        let diff = eventTime.diff(current);
        let duration = moment.duration(diff);
        return duration.humanize(true).replace("vor", "seit");
    }

    saveAndClose() {
        window.localStorage.setItem('unreachableIgnore', JSON.stringify(this.props.unavailable));
        this.setState({open: false});
    }

    checkForNewAlerts() {
        let ignored = window.localStorage.getItem('unreachableIgnore');
        if (ignored) {
            this.unreachableToIgnore = JSON.parse(ignored); 
        }
        let newAlerts = 0;
        for (let i = 0; i < this.props.unavailable.length; ++i) {
            let value = this.props.unavailable[i];
            let index = this.unreachableToIgnore.findIndex(x => (x.device == value.device && x.since == value.since));
            if (index >= 0) {
                this.props.unavailable[i].new = false;
            } else {
                this.props.unavailable[i].new = true;
                newAlerts++;
            }
        }
        return newAlerts;
    }

    render() {
        if (this.props.deviceData && this.props.unavailable && this.props.unavailable.length>0) {
            let currentTime = moment();
            let countChanged = this.checkForNewAlerts();
            let hasChanged = countChanged > 0;
            let shouldBeOpen = hasChanged;
            if (hasChanged && !this.state.open) {
                this.setState({open: true});
            }
            if (!shouldBeOpen) { 
                return <></>
            } else {
            this.props.unavailable.sort(sortByTime);
            return  <Grid item style={{width: '100%'}}>
            <Collapse in={this.state.open}> 
                <Alert 
                    action={
                        <IconButton
                            style={{alignSelf: 'self-start'}}
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {this.saveAndClose()}}
                        >
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>
                    }
                    elevation={6}
                    variant="outlined"
                    severity="error"
                >
                <AlertTitle>{this.props.unavailable.length > 1 ? "Komponenten" : "Komponente"} nicht erreichbar!</AlertTitle>
                <span style={{fontWeight: "bold"}}>{this.props.unavailable.length}</span> {this.props.unavailable.length > 1 ? "Komponenten sind" : "Komponente ist"} aktuell nicht erreichbar (davon <span style={{fontWeight: "bold"}}>{countChanged}</span> neu):
                <List dense={true}>
                {this.props.unavailable.map((item) => (
                    <ListItem>
                        <ListItemIcon style={{color: 'inherit'}}>
                            {item.new ? <PriorityHighIcon/> : <PortableWifiOffIcon/>}    
                        </ListItemIcon>
                        <ListItemText
                            primary={this.props.deviceData[item.device].mName}
                            secondary={this.renderDuration(item.since, currentTime)}
                        />
                    </ListItem>
                ))}
                </List>
            </Alert>
            </Collapse>
            </Grid>
            }
        } else {
            return <></>
        }
    }
}

export default UnresponsiveAlert;