import React from 'react';
import { Helmet } from 'react-helmet';
import Div100vh from 'react-div-100vh'
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';
import EventsPage from './pages/EventsPage';
import GraphsPage from './pages/GraphsPage';
import PrivateRoute from './components/PrivateRoute';
import { history } from './helpers/routing'
import {
  Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Sidebar from './components/sidebar';
import Titlebar from './components/titlebar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { session } from './security/sessionmanager';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import connection from './services/connection';
import transforms from './helpers/transforms';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import './css/main.css';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    paddingLeft: 16
  },
  toolbarLight: {
    backgroundColor: '#556cd6',
  },
  toolbarDark: {
    backgroundColor: '#192767',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  toolbarIcon2: {
    marginRight: 12,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 24,
  },
  menuButtonHidden: {
    display: 'none',
  },
  titleLeft: {
    flexGrow: 1,
  },
  titleRight: {
    alignSelf: 'right',
  },
  drawer: {
    minHeight: '100vh',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    height: '100%',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '100%'
  },
  fixedHeight: {
    height: 300,
  },
  widthFlex: {
    display: 'flex',
  }
}));

let isLoadingDeviceData = false;
let isLoadingFloorData = false;
let isLoadingRoomData = false;
let isLoadingDeviceTypeData = false;
let isLoadingCapabilityData = false;
let loadingCount = 0;

export default function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // A custom theme for this app
  const theme = React.useMemo(
    () => 
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? 'dark': 'light',
          primary: {
            main: '#556cd6' ,
          },
          secondary: {
            main: prefersDarkMode ? '#663300' :'#f57c00',
          },
          error: {
            main: red.A400,
          },
        }
      }),
    [prefersDarkMode]
  );
  const classes = useStyles();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [open, setOpen] = React.useState(isDesktop);
  const [loading, setLoading] = React.useState(false);
  const [mode, setMode] = React.useState('yearly');
  const [years, setYears] = React.useState(['2020']);
  const [logged, setLogged] = React.useState(false);
  const [deviceData, setDeviceData] = React.useState(null);
  const [floorData, setFloorData] = React.useState(null);
  const [roomData, setRoomData] = React.useState(null);
  const [deviceTypeData, setDeviceTypeData] = React.useState(null);
  const [capabilityData, setCapabilityData] = React.useState(null);
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
      setIsMounted(true);
  }, []);

  React.useEffect(() => {
      return () => {
          setIsMounted(false);
      }
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLoadingStart = () => {
    loadingCount = loadingCount + 1;
    if (loadingCount > 0) {
      setLoading(true);
    }
  }

  const handleLoadingStop = () => {
    loadingCount = loadingCount - 1;
    if (loadingCount === 0) {
      setLoading(false);
    }
    
  }

  let isAuth = session.isAuthenticated();
  if (logged != isAuth) {
    setLogged(isAuth);
  }
  let isLogin = (history.location.pathname === "/login");
  
  let loadDeviceData = function() {
    handleLoadingStart();
    isLoadingDeviceData = true;
    let URL = "/devices";
    connection.get(URL).then((result) => {
        let trDeviceData = transforms.arrayToObject(result.data.devices, "id");
        setDeviceData(trDeviceData);
        handleLoadingStop();
        isLoadingDeviceData = false;
    }).catch((error) => { handleLoadingStop(); console.log(error)});
  }

  let loadFloorData = function() {
    handleLoadingStart();
    isLoadingFloorData = true;
    let URL = "/floors";
    connection.get(URL).then((result) => {
        let trFloorData = transforms.arrayToObject(result.data.floors, "id");
        setFloorData(trFloorData);
        handleLoadingStop();
        isLoadingFloorData = false;
    }).catch((error) => { handleLoadingStop(); console.log(error)});
  }

  let loadRoomData = function() {
    handleLoadingStart();
    isLoadingRoomData = true;
    let URL = "/rooms";
    connection.get(URL).then((result) => {
        let trRoomData = transforms.arrayToObject(result.data.rooms, "id");
        setRoomData(trRoomData);
        handleLoadingStop();
        isLoadingRoomData = false;
    }).catch((error) => { handleLoadingStop(); console.log(error)});
  }

  let loadDeviceTypeData = function() {
    handleLoadingStart();
    isLoadingDeviceTypeData = true;
    let URL = "/types/capabilities";
    connection.get(URL).then((result) => {
        let trDeviceTypeData = transforms.arrayToObject(result.data.types, "id");
        setDeviceTypeData(trDeviceTypeData);
        handleLoadingStop();
        isLoadingDeviceTypeData = false;
    }).catch((error) => { handleLoadingStop(); console.log(error)});
  }

  let loadCapabilityData = function() {
    handleLoadingStart();
    isLoadingCapabilityData = true;
    let URL = "/capabilities";
    connection.get(URL).then((result) => {
        let trCapabilityData = transforms.arrayToObject(result.data.capabilities, "id");
        setCapabilityData(trCapabilityData);
        handleLoadingStop();
        isLoadingCapabilityData = false;
    }).catch((error) => { handleLoadingStop(); console.log(error)});
  }

  if (!deviceData && logged && !isLoadingDeviceData && isMounted) {
    loadDeviceData();
  }

  if (!floorData && logged && !isLoadingFloorData && isMounted) {
    loadFloorData();
  }

  if (!roomData && logged && !isLoadingRoomData && isMounted) {
    loadRoomData();
  }

  if (!deviceTypeData && logged && !isLoadingDeviceTypeData && isMounted) {
    loadDeviceTypeData();
  }

  if (!capabilityData && logged && !isLoadingCapabilityData && isMounted) {
    loadCapabilityData();
  }

  return (
    <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <MuiPickersUtilsProvider utils={MomentUtils}>
    <Router history={history}>
      <Helmet>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Helmet>
      <Div100vh style={{height: '100rvh', display: 'flex'}}>
        <Titlebar classes={classes} prefersDarkMode={prefersDarkMode} open={open} handleOpen={handleDrawerOpen} loading={loading}/>
        <Sidebar classes={classes} open={open} handleClose={handleDrawerClose} large={isDesktop}/>
        <Switch>
          <Route 
            exact path="/"
            render={() => { return (<Redirect to="/overview" />)}}
          />
          <Route 
            exact
            path="/login"
            component={() => <LoginPage hasSuccess={setLogged}/>}
          />
          <PrivateRoute
            exact
            path="/overview"
            deviceData={deviceData}
            floorData={floorData}
            roomData={roomData}
            deviceTypeData={deviceTypeData}
            capabilityData={capabilityData}
            isDesktop={isDesktop}
            prefersDarkMode={prefersDarkMode}
            handleLoadingStart={handleLoadingStart}
            handleLoadingStop={handleLoadingStop}
            component={MainPage}
          />
          <PrivateRoute
            exact
            path="/events"
            deviceData={deviceData}
            isDesktop={isDesktop}
            prefersDarkMode={prefersDarkMode}
            handleLoadingStart={handleLoadingStart}
            handleLoadingStop={handleLoadingStop}
            component={EventsPage}  
          />
          <PrivateRoute 
            path="/events/:year([0-9]{4})-:month([0-9]{2})-:day([0-9]{2})"
            isDesktop={isDesktop}
            prefersDarkMode={prefersDarkMode}
            deviceData={deviceData}
            handleLoadingStart={handleLoadingStart}
            handleLoadingStop={handleLoadingStop}
            component={EventsPage}
          />
          <PrivateRoute
            exact
            path="/history"
            deviceData={deviceData}
            handleLoadingStart={handleLoadingStart}
            handleLoadingStop={handleLoadingStop}
            isDesktop={isDesktop}
            prefersDarkMode={prefersDarkMode}
            floorData={floorData}
            roomData={roomData}
            deviceTypeData={deviceTypeData}
            capabilityData={capabilityData}
            component={GraphsPage}
          />
          <PrivateRoute
            path="/history/device/:device([0-9]+)/:year([0-9]{4})-:month([0-9]{2})-:day([0-9]{2})"
            isDesktop={isDesktop}
            prefersDarkMode={prefersDarkMode}
            deviceData={deviceData}
            floorData={floorData}
            roomData={roomData}
            deviceTypeData={deviceTypeData}
            capabilityData={capabilityData}
            handleLoadingStart={handleLoadingStart}
            handleLoadingStop={handleLoadingStop}
            component={GraphsPage}
          />
          <PrivateRoute
            path="/history/device/:device([0-9]+)"
            isDesktop={isDesktop}
            prefersDarkMode={prefersDarkMode}
            deviceData={deviceData}
            floorData={floorData}
            roomData={roomData}
            deviceTypeData={deviceTypeData}
            capabilityData={capabilityData}
            handleLoadingStart={handleLoadingStart}
            handleLoadingStop={handleLoadingStop}
            component={GraphsPage}
          />
          {!isAuth ? <Redirect key="redirect-login" to="/login" /> : <Redirect key="redirect-dne" to="/"/>}
        </Switch>
      </Div100vh>
    </Router>
    </MuiPickersUtilsProvider>
    </ThemeProvider>
    
  );
}
