import React from 'react';

let graphs = {
    1: [
        {
            name: "Position",
            axisTitle: "Position",
            unit: "%",
            min: 0,
            max: 100,
            capabilities: [7],
        },
        {
            name: "Lamellenposition",
            axisTitle: "Lamellenposition",
            unit: "%",
            min: 0,
            max: 100,
            capabilities: [8],
        },
        {
            name: "Meldungen",
            axisTitle: "Zustand",
            min: 0,
            max: 1,
            stepSize: 1,
            tickCallback: (label, index, labels) => {
                switch (label) {
                    case 0: return "nein";
                    case 1: return "ja";
                }
            },
            capabilities: [1,9],
        },
        {
            name: "Automatiken",
            axisTitle: "Zustand",
            min: 0,
            max: 3,
            stepSize: 1,
            tickCallback: (label, index, labels) => {
                switch (label) {
                    case 0: return "ungenutzt";
                    case 1: return "scharf";
                    case 2: return "deaktiviert";
                    case 3: return "läuft";
                }
            },
            capabilities: [19,21,22,23,27]
        }
    ],
    2: [
        {
            name: "Position",
            axisTitle: "Position",
            unit: "%",
            min: 0,
            max: 100,
            capabilities: [7],
        },
        {
            name: "Meldungen",
            axisTitle: "Zustand",
            min: 0,
            max: 1,
            stepSize: 1,
            tickCallback: (label, index, labels) => {
                switch (label) {
                    case 0: return "nein";
                    case 1: return "ja";
                }
            },
            capabilities: [1,9],
        },
        {
            name: "Automatiken",
            axisTitle: "Zustand",
            min: 0,
            max: 3,
            stepSize: 1,
            tickCallback: (label, index, labels) => {
                switch (label) {
                    case 0: return "ungenutzt";
                    case 1: return "scharf";
                    case 2: return "deaktiviert";
                    case 3: return "läuft";
                }
            },
            capabilities: [19,21,22,23,27]
        }
    ],
    3: [
        {
            name: "Zustand",
            axisTitle: "Zustand",
            min: 0,
            max: 1,
            stepSize: 1,
            tickCallback: (label, index, labels) => {
                switch (label) {
                    case 0: return "aus";
                    case 1: return "an";
                }
            },
            capabilities: [28],
        },
        {
            name: "Meldungen",
            axisTitle: "Zustand",
            min: 0,
            max: 1,
            stepSize: 1,
            tickCallback: (label, index, labels) => {
                switch (label) {
                    case 0: return "nein";
                    case 1: return "ja";
                }
            },
            capabilities: [1,9],
        },
        {
            name: "Automatiken",
            axisTitle: "Zustand",
            min: 0,
            max: 3,
            stepSize: 1,
            tickCallback: (label, index, labels) => {
                switch (label) {
                    case 0: return "ungenutzt";
                    case 1: return "scharf";
                    case 2: return "deaktiviert";
                    case 3: return "läuft";
                }
            },
            capabilities: [19,21,22,23,27]
        }
    ],
    4: [
        {
            name: "Zustand",
            axisTitle: "Zustand",
            min: 0,
            max: 1,
            stepSize: 1,
            tickCallback: (label, index, labels) => {
                switch (label) {
                    case 0: return "aus";
                    case 1: return "an";
                }
            },
            capabilities: [28],
        },
        {
            name: "Meldungen",
            axisTitle: "Zustand",
            min: 0,
            max: 1,
            stepSize: 1,
            tickCallback: (label, index, labels) => {
                switch (label) {
                    case 0: return "nein";
                    case 1: return "ja";
                }
            },
            capabilities: [1,9],
        },
        {
            name: "Automatiken",
            axisTitle: "Zustand",
            min: 0,
            max: 3,
            stepSize: 1,
            tickCallback: (label, index, labels) => {
                switch (label) {
                    case 0: return "ungenutzt";
                    case 1: return "scharf";
                    case 2: return "deaktiviert";
                    case 3: return "läuft";
                }
            },
            capabilities: [19,21,22,23,27]
        }
    ],
    5: [
        {
            name: "Helligkeit",
            min: 0,
            max: 100,
            axisTitle: "Helligkeit",
            unit: "%",
            capabilities: [10],
        },
        {
            name: "Meldungen",
            axisTitle: "Zustand",
            min: 0,
            max: 1,
            stepSize: 1,
            tickCallback: (label, index, labels) => {
                switch (label) {
                    case 0: return "nein";
                    case 1: return "ja";
                }
            },
            capabilities: [1,9],
        },
        {
            name: "Automatiken",
            axisTitle: "Zustand",
            min: 0,
            max: 3,
            stepSize: 1,
            tickCallback: (label, index, labels) => {
                switch (label) {
                    case 0: return "ungenutzt";
                    case 1: return "scharf";
                    case 2: return "deaktiviert";
                    case 3: return "läuft";
                }
            },
            capabilities: [19,21,22,23,27]
        }
    ],
    6: [
        {
            name: "Batteriestand",
            axisTitle: "Batteriestand",
            min: 0,
            max: 100,
            unit: "%",
            capabilities: [6],
        },
        {
            name: "Meldungen",
            axisTitle: "Zustand",
            min: 0,
            max: 1,
            stepSize: 1,
            tickCallback: (label, index, labels) => {
                switch (label) {
                    case 0: return "nein";
                    case 1: return "ja";
                }
            },
            capabilities: [1,4,5],
        }
    ],
    7: [
        {
            name: "Position",
            axisTitle: "Position",
            unit: "%",
            min: 0,
            max: 100,
            capabilities: [7],
        },
        {
            name: "Meldungen",
            axisTitle: "Zustand",
            min: 0,
            max: 1,
            stepSize: 1,
            tickCallback: (label, index, labels) => {
                switch (label) {
                    case 0: return "nein";
                    case 1: return "ja";
                }
            },
            capabilities: [1,9],
        },
        {
            name: "Automatiken",
            axisTitle: "Zustand",
            min: 0,
            max: 3,
            stepSize: 1,
            tickCallback: (label, index, labels) => {
                switch (label) {
                    case 0: return "ungenutzt";
                    case 1: return "scharf";
                    case 2: return "deaktiviert";
                    case 3: return "läuft";
                }
            },
            capabilities: [19,21,22,23,27]
        }
    ],
    8: [
        {
            name: "Temperatur",
            axisTitle: "Temperatur",
            capabilities: [14],
            unit: "ºC"
        },
        {
            name: "Windgeschwindigkeit",
            axisTitle: "Geschwindigkeit",
            unit: "m/s",
            capabilities: [29],

        },
        {
            name: "Sonnenhelligkeit",
            axisTitle: "Helligkeit",
            unit: "klx",
            tickCallback: (label, index, labels) => {
                return label/1000;
            },
            capabilities: [11]
        },
        {
            name: "Sonnenwinkel",
            axisTitle: "Winkel",
            unit: "º",
            min: 0,
            max: 360,
            stepSize: 60,
            capabilities: [12],
        },
        {
            name: "Sonnenhöhe",
            axisTitle: "Winkel",
            unit: "º",
            capabilities: [13],
        },
        {
            name: "Meldungen",
            axisTitle: "Zustand",
            min: 0,
            max: 1,
            stepSize: 1,
            tickCallback: (label, index, labels) => {
                switch (label) {
                    case 0: return "nein";
                    case 1: return "ja";
                }
            },
            capabilities: [1,2,3]
        },
    ]
}

export default graphs;