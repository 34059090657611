
let arrayToObject = function (array, key) {
        const initialValue = {};
        return array.reduce((obj, item) => {
            return {
                ...obj,
                [item[key]]: item,
            };
        }, initialValue);
}

let arrayToObjectWithSingleValue = function(array, key, valueKey) {
        const initialValue = {};
        return array.reduce((obj, item) => {
            return {
                ...obj,
                [item[key]]: item[valueKey],
            };
        }, initialValue);
}

export default {arrayToObject: arrayToObject, arrayToObjectWithSingleValue: arrayToObjectWithSingleValue};