import React, { Component } from 'react';
import MdiIcon from '@mdi/react';
import { mdiBattery } from '@mdi/js';
import { mdiBattery10 } from '@mdi/js';
import { mdiBattery20 } from '@mdi/js';
import { mdiBattery30 } from '@mdi/js';
import { mdiBattery40 } from '@mdi/js';
import { mdiBattery50 } from '@mdi/js';
import { mdiBattery60 } from '@mdi/js';
import { mdiBattery70 } from '@mdi/js';
import { mdiBattery80 } from '@mdi/js';
import { mdiBattery90 } from '@mdi/js';
import { mdiBatteryOutline } from '@mdi/js';

class MdiFire extends Component {
    getPath(value) {
        let cat = Math.floor(value/10);
        switch (cat) {
            case 0: return mdiBatteryOutline;
            case 1: return mdiBattery10;
            case 2: return mdiBattery20;
            case 3: return mdiBattery30;
            case 4: return mdiBattery40;
            case 5: return mdiBattery50;
            case 6: return mdiBattery60;
            case 7: return mdiBattery70;
            case 8: return mdiBattery80;
            case 9: return mdiBattery90;
            case 10: return mdiBattery;
            default: return mdiBatteryOutline;
        }
    }

    render() {
        let { className, value, ...rest} = this.props;
        return (
            <MdiIcon path={this.getPath(value)} className={[this.props.className, "MuiSvgIcon-root"].join(' ')}  {...rest}/>
        );
    }
}

export default MdiFire;