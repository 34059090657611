import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import GraphContainer from '../components/GraphContainer';
import HistorySelectionBar from '../components/HistorySelectionBar';
import Grid from '@material-ui/core/Grid';
import connection from '../services/connection';
import 'moment/locale/de';
import moment from 'moment-timezone';
import graphs from '../helpers/graphs';
import Copyright from '../components/Copyright';
let axios = require('axios');
let CancelToken = axios.CancelToken;


const useStyles = makeStyles((theme) => ({
    contentDesktop: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1,
        overflow: 'auto',
        marginTop: '64px',
        paddingTop: '20px',
        height: 'calc(100% - 64px)'
    },
    contentMobile: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1,
        overflow: 'auto',
        marginTop: '56px',
        paddingTop: '20px',
        height: 'calc(100% - 56px)'
    },
    selectionBarContainer: {
        marginTop: '15px',
        width: '100%'
    },
    graphsContainer: {
        width: '100%'
    }
}));

let currentDevice = null;
let currentDate = null;
let dateValid = true;
let loadedDevice = null;
let loadedDate = null;
let isLoading = false;

function GraphsPage(props) {
    document.title = "Homepilot - Verlauf";
    let cancel;
    const {deviceData, floorData, roomData, deviceTypeData, capabilityData, isDesktop, prefersDarkMode, handleLoadingStart, handleLoadingStop} = props;
    const [data, setData] = useState(null);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        return () => {
            setIsMounted(false);
        }
    }, []);

    let changeInData = function() {
        return ((loadedDevice != currentDevice || loadedDate.format("YYYY-MM-DD") != currentDate.format("YYYY-MM-DD"))); 
    }

    let device = parseInt(props.match.params.device);
    currentDate = moment().tz("Europe/Berlin");
    currentDate.hours(0);
    currentDate.minutes(0);
    currentDate.seconds(0);
    currentDate.milliseconds(0);

    if (props.match.params.year) {
        let year = parseInt(props.match.params.year);
        let month = parseInt(props.match.params.month);
        let day = parseInt(props.match.params.day);
        let date = moment.tz([year, month-1, day ], "Europe/Berlin");
        if (date.isValid()) {
            currentDate = date;
            dateValid = true;
        } else {
            dateValid = false;
        }
    }

    if (!device) {
        currentDevice = 40;
    } else {
        currentDevice = device;
    }

    let selectionChange = function(data) {
        currentDevice = data.device;
        currentDate = data.date;
        if (changeInData()) {
            let targetDate = data.date.format("YYYY-MM-DD");
            let target = `/history/device/${currentDevice}/${targetDate}`;
            props.history.push(target);
            setData(null);

        }
        
    }

    let loadData = function() {
        handleLoadingStart();
        isLoading = true;
        let URL = "/data/device/" + currentDevice + "/bytype/" + currentDate.format("YYYY-MM-DD");
        connection.get(
            URL, 
            {cancelToken: new CancelToken(c => cancel = c)}).then((result) => {
                
                let newData = result.data;
                handleLoadingStop();
                isLoading = false;
                loadedDate = currentDate;
                loadedDevice = currentDevice;
                setData(newData);
        }).catch((error) => { handleLoadingStop(); console.log(error)});
    }

    if ((!data || changeInData()) && isMounted && dateValid && !isLoading) {
        setData(null);
        loadData();
    }
    
    
    const classes = useStyles();
    let allData = deviceData && floorData && roomData && deviceTypeData && capabilityData;
    if (allData) {
        
    return (
        <Container component="div" maxWidth={false} className={isDesktop ? classes.contentDesktop : classes.contentMobile}>
        <Grid
            container
            spacing={2}
        />
        <Grid item className={classes.selectionBarContainer}>
        <HistorySelectionBar
            deviceData={deviceData}
            floorData={floorData}
            roomData={roomData}
            deviceTypeData={deviceTypeData}
            capabilityData={capabilityData}
            isDesktop={isDesktop}
            prefersDarkMode={prefersDarkMode}
            date={currentDate}
            device={currentDevice}
            onChange={selectionChange}
        />
        </Grid>
        <Grid item className={classes.graphsContainer}>
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className = {isDesktop ? classes.selectionBar : classes.selectionbarSmall}
            spacing={2}
        >
            {graphs[deviceData[currentDevice].type].map((item, index) => (
                <GraphContainer key={`graph-${currentDevice}-${index}`}graphs={item} data={data} capabilityData={capabilityData} prefersDarkMode={prefersDarkMode}/>
            ))}
        </Grid>
        </Grid>
        <Copyright />
        </Container>
    )
        } else { return (<></>)}

}

export default GraphsPage;