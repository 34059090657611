import React, { Component } from 'react';
import MdiIcon from '@mdi/react';
import { mdiLightbulb, mdiLightbulbOffOutline } from '@mdi/js';

class MdiLightbulb extends Component {
    render() {
        let { className, value, ...rest} = this.props;
        if (value > 0) {
            return (
                <MdiIcon path={mdiLightbulb} className={[this.props.className, "MuiSvgIcon-root"].join(' ')} color="#fdd835" {...rest}/>
            );
        } else {
            return (
                <MdiIcon path={mdiLightbulbOffOutline} className={[this.props.className, "MuiSvgIcon-root"].join(' ')} {...rest}/>
            );
        }
    }
}

export default MdiLightbulb;