import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Copyright from '../components/Copyright';
import {session} from '../security/sessionmanager';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

  function LoginPage(props) {
    document.title = "Anmelden";
    const { location, history, hasSuccess } = props;
    const { state } = location;
    const classes = useStyles();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginUnsuccessful, setLoginUnsuccessful] = useState(false);

    const onChangeUsername = (e) => {
      const username = e.target.value;
      setUsername(username);
    };
  
    const onChangePassword = (e) => {
      const password = e.target.value;
      setPassword(password);
    };

    const handleLogin = (e) => {
      e.preventDefault();
      setLoginUnsuccessful(false);
      session.login(username, password).then(
        () => {
          console.log(state);
          if (state && state.from && state.from.pathname !== '/login') {
            console.log(state.from);
            hasSuccess(true);
            history.replace(state.from);
          } else {
            hasSuccess(true);
            history.replace('/');
          }
        },
        (error) => {
          setLoginUnsuccessful(true);
        }
      )
    }

  
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Anmelden
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email/Benutzer"
              name="email"
              autoComplete="email"
              onChange={onChangeUsername}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Passwort"
              type="password"
              id="password"
              onChange={onChangePassword}
              autoComplete="current-password"
            />
            <Button
              size="large"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleLogin}
            >
              Anmelden
            </Button>
          </form>
        </div>
        {loginUnsuccessful && <Box>
          <Alert severity="error">
            <AlertTitle>Anmeldung fehlgeschlagen!</AlertTitle>
            Falscher Benutzername und/oder Passwort
          </Alert>
        </Box>}
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }

export default withRouter(LoginPage);