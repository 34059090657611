import { history } from '../helpers/routing'
var connection = require('../services/connection');

var getInstance = function() {
    if (global.smInstanceHomePilot === undefined) {
        global.smInstanceHomePilot = new SessionManager();
    }
    return global.smInstanceHomePilot;

}

class SessionManager {
    static LOCAL_STORAGE_TOKEN = 'token';

    constructor() {
        
    }

    refreshToken() {
        return new Promise((resolve, reject) => {
            connection.get("/users/refreshtoken", {withCredentials: true}).then((response) => {
                this.storeToken(response.data.token);
                resolve(response.data.token);
            })
            .catch((error) => {
                reject(error);
            })
        }
        )
    }

    storeToken(token) {
        localStorage.setItem(SessionManager.LOCAL_STORAGE_TOKEN, token);
    }

    clear() {
        localStorage.removeItem(SessionManager.LOCAL_STORAGE_TOKEN);
        history.push('/login', {from: history.location});
    }

    login(username, password) {
        return new Promise((resolve, reject) => {
            connection.post('/users/authenticate', {username: username, password: password})
            .then((response) => {
                this.storeToken(response.data.token);
                resolve(response.data.token);
            })
            .catch((error) => { reject(error) });
        });
    }

    getToken() {
        return localStorage.getItem(SessionManager.LOCAL_STORAGE_TOKEN);
    }

    isAuthenticated() {
        return this.getToken() !== null;
    }
}

export const session = getInstance();