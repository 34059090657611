import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles';
import 'moment/locale/de';
import moment from 'moment-timezone';
import Chart from "chart.js";
import 'chartjs-adapter-moment';
import Typography from '@material-ui/core/Typography';
import ValueTransforms from '../helpers/valueTransforms';
import graphColors from '../helpers/graphColors';
import 'chartjs-plugin-zoom';


const styles = theme => ({
    chart: {
        position: 'relative',
        height: '100%',
        width: '100%',
        flexGrow: 1
    }
});

class Graph extends Component {
    chartRef = React.createRef();
    chart = undefined;

    constructor(props) {
        super(props);
        this.setupGraph = this.setupGraph.bind(this);
        this.setVisibility = this.setVisibility.bind(this);
        this.currentUnit = "";
    }

    componentDidUpdate(prevProps) {
        if (this.chart) {
            if (prevProps.prefersDarkMode !== this.props.prefersDarkMode) {
                Chart.defaults.global.defaultFontColor = this.props.prefersDarkMode ? '#eee' : '#666';
                this.chart.update(0);
            }
        }
    }

    setupGraph() {
        const myChartRef = this.chartRef.current.getContext("2d");
        let minUnix = this.props.data.begin;
        let maxUnix = this.props.data.end;
        let minDate = new Date(minUnix * 1000);
        let maxDate = new Date(maxUnix * 1000);
        Chart.defaults.global.defaultFontFamily = "'Roboto', 'Helvetica', 'Arial', sans-serif";
        let language = window.navigator.userLanguage || window.navigator.language;
        let datasets = this.props.graphs.capabilities.map((item) => {
            let data = this.props.data.data[item];
            let values = data.map((datum) => {
                return {x:new Date(datum.time * 1000), y: ValueTransforms[item](datum.current)};
            })
            if (values[0].x.getTime()/1000 > minUnix) {
                values.unshift({x: new Date((minUnix-600)*1000), y: ValueTransforms[item](data[0].previous)});
            }
            let currentTime = moment().tz("Europe/Berlin");
            if (maxDate < currentTime) {
                values.push({x: new Date((maxUnix+600)*1000), y: ValueTransforms[item](data[data.length-1].current)});
            } else {
                values.push({x: currentTime.toDate(), y: ValueTransforms[item](data[data.length-1].current)});
            }
            return {
                hoverBorderWidth: 5,
                fill: true,
                label: item === 1 ? "Nicht erreichbar" : this.props.capabilityData[item].description,
                type: 'line',
                data: values,
                pointRadius: 1,
                steppedLine: true,
                borderColor: graphColors.border[item],
                hoverBorderColor: graphColors.border[item], 
                backgroundColor: graphColors.background[item],
                hoverBackgroundColor: graphColors.background[item]
            }
        });
        Chart.defaults.global.defaultFontColor = this.props.prefersDarkMode ? '#eee' : '#666';
        
        this.chart = new Chart(myChartRef, {
            data: {
                //Bring in data
                datasets: datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: this.props.graphs.capabilities.length === 1 ? false : true,
                    position: 'bottom'
                },
                plugins: {
                    zoom: {
                        pan: {
                            enabled: true,
                            mode: 'x',
                            rangeMin: {
                                x: minDate
                            },
                            rangeMax: {
                                x: maxDate
                            }
                        },
                        zoom: {
                            enabled: true,
                            mode: 'x',
                            rangeMin: {
                                x: minDate
                            },
                            rangeMax: {
                                x: maxDate
                            }
                        }
                    }
                },
                scales: {
					xAxes: [{
                        type: 'time',
                        time: {
                            unit: 'hour',
                            unitStepSize: 1,
                            displayFormats: {
                               'hour': 'HH:mm'
                            }
                        },
                        ticks: {
                            fontSize: 12,
                            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                            min: minDate,
                            max: maxDate
                        },
                        adapters: {
                            date: {
                            }
                        }
						
					}],
					yAxes: [{
						gridLines: {
							drawBorder: false
						},
						scaleLabel: {
							display: true,
                            labelString: 'Jahresverbrauch',
                            fontSize: 16
                        },
                        ticks: {
                            fontSize: 12,
                            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                        }
					}]
                },
                hover: {
                    mode: 'dataset',
                    intersect: true
                },
                tooltips: {
                    callbacks: {
                      label: (tooltipItem, data) => {
                        var tooltipValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y;
                        var digits = 1;
                       
                        return tooltipValue.toLocaleString(language, {maximumFractionDigits: digits}) + ' ' + this.currentUnit;
                      },
                      title: function(tooltipItems, data) {
                          let tooltipItem = tooltipItems[0];
                          let DSindex = tooltipItem.datasetIndex;
                          let index = tooltipItem.index;
                          let x = data.datasets[DSindex].data[index].x;
                          return x.toLocaleString(language, {hour: "numeric", minute: "numeric"});
                    },
                    }
                }

                //Customize chart options
            }
        });
    }

    setVisibility() {
        if (!this.chart) return;
        if (this.props.graphs.unit) {
            this.chart.options.scales.yAxes[0].scaleLabel.labelString = this.props.graphs.axisTitle + " / " + this.props.graphs.unit;
        } else {
            this.chart.options.scales.yAxes[0].scaleLabel.labelString = this.props.graphs.axisTitle;
        }
        if (this.props.graphs.min !== undefined) {
            this.chart.options.scales.yAxes[0].ticks.min = this.props.graphs.min;
            this.chart.options.scales.yAxes[0].ticks.max = this.props.graphs.max;
        }
        if (this.props.graphs.stepSize !== undefined) {
            this.chart.options.scales.yAxes[0].ticks.stepSize = this.props.graphs.stepSize;
        }
        if (this.props.graphs.tickCallback !== undefined) {
            this.chart.options.scales.yAxes[0].ticks.callback = this.props.graphs.tickCallback;
        }
        this.chart.update(0);
    }

    componentDidMount() {
        if (!this.chart && this.props.data && this.props.graphs) {
            this.setupGraph();
            
        }
        if (this.chart) {
            this.setVisibility();
        }
    }

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.chart}>
                <canvas
                    id="myChart"
                    ref={this.chartRef}
                />
            </div>
        )

    }
}

export default withStyles(styles)(Graph);